import * as React from 'react'
import * as styles from './assets/css/index.module.css'
import ArrowLeftIcon from './assets/images/chevron-left.svg'
import ArrowRightIcon from './assets/images/chevron-right.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Finance, Supplies, Top, Cagr } from './images'
import { Container } from '../../common'
import { Bean } from '../../bean'
import { Fact } from '..'

interface Props extends Bean {}

const Facts: React.FC<Props> = ({
  className = '',
  style = {},
  children,
}) => {
  const { t } = useTranslation()
  return (
    <section className={`${styles.root} ${className}`} style={style}>
      <Container>
        {/* <div className={`${styles.arrow} ${styles.left}`}>
          <ArrowLeftIcon />
        </div>
        <div className={`${styles.arrow} ${styles.right}`}>
          <ArrowRightIcon />
        </div> */}
        <div className={styles.wrapper}>
          <Fact title={t('Объем')} description={t('Объем общего выплаченного финансирования')}>
            <Finance />
          </Fact>
          <Fact title={t('Поставок')} description={t('Профинансированных поставок')}>
            <Supplies />
          </Fact>
          <Fact title={t('ТОР')} description={t('факторинговых компаний в сегменте МСП')}>
            <Top />
          </Fact>
          <Fact title={t('темп роста')} description={t('совокупный среднегодовой темп роста')}>
            <Cagr />
          </Fact>
        </div>
      </Container>
    </section>
  )
}


export default Facts