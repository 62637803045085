// extracted by mini-css-extract-plugin
export var root = "M_b";
export var greeting = "M_b8";
export var figure = "M_bF";
export var wrapper = "M_r";
export var image = "M_bb";
export var text = "M_J";
export var quotes = "M_b9";
export var signature = "M_cb";
export var mission = "M_cc";
export var paragraph = "M_N";
export var svg = "M_cd";