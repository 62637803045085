import React, { useState } from 'react'
import FolderOpenedIcon from './assets/images/folder-opened.svg'
import FolderClosedIcon from './assets/images/folder-closed.svg'
import { Bean } from '../../bean'
import * as styles from './assets/css/category.module.css'

export interface Categoty extends Bean {
  opened?: boolean
  onClick: () => void
}

const Categoty: React.FC<Categoty> = ({
  children,
  className = '',
  style,
  opened = false,
  onClick,
}) => {
  const Icon = opened ? <FolderOpenedIcon /> : <FolderClosedIcon/>
  const titleClass = opened ? styles.opened : ''
  return (
    <div className={`${styles.root} ${className}`} style={style} onClick={() => onClick()}>
      <div className={styles.icon}>{Icon}</div>
      <div className={`${styles.title} ${titleClass}`}>{children}</div>
    </div>
  )
}

export default Categoty