import React from 'react'
import { Bean } from '../../bean'
import * as styles from './assets/css/event.module.css'
import ChevronRight from './assets/images/chevron-right.svg'
import BlogItem from './assets/images/blog-item.svg'

interface Props extends Bean {
  date: Date | string
  onClick: () => void
}

const Event: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  date,
  onClick
}) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.icon}>
        <BlogItem />
      </div>
      <div className={styles.title}>
        <h6 className={styles.date}>{date}</h6>
        <p className={styles.text}>{children}</p>
      </div>
      <div className={styles.arrow}>
        <ChevronRight />
      </div>
    </div>
  )
}

export default Event