import * as React from 'react'
import * as styles from './assets/css/index.module.css'
import * as utilities from '../../../assets/css/utilities.module.css'
import { Container, Heading, Paragraph } from '../../common'
import { Bean } from '../../bean'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Graph } from '..'
import { Revenue, Digitization, Portfolio } from './images'

interface Props extends Bean {}

const Infographics: React.FC<Props> = ({
  className = '',
  style = {},
  children,
}) => {
  const { t } = useTranslation()
  return (
    <section className={`${styles.root} ${className}`} style={style}>
      <Container className={styles.grid}>
          <article className={styles.article}>
            <Heading>
              <Trans>Наша компания</Trans>
            </Heading>
            <Paragraph className={utilities.mt2_5}>
              <Trans>GLOBAL FACTORING NETWORK является факторинговой fintech-платформой, в котором в полной мере реализован цифровой клиентский путь с использованием in-house решений, а так же высококачественных digital-продуктов от партнеров.</Trans>
            </Paragraph>
            <Paragraph className={utilities.mt1_375}>
              <Trans>Основное направление деятельности — online-решения для бизнеса, а именно оперативное финансирование оборотного капитала предприятий под уступку надежной краткосрочной дебиторской задолженности с использованием blockchain технологий и факторинга в безбумажной электронной среде.</Trans>
            </Paragraph>
          </article>
        <div className={styles.graphs}>
          <Graph title={t('Планы по выручке')}>
            <Revenue />
          </Graph>
          <Graph title={t('Факторинговый портфель')}>
            <Portfolio />
          </Graph>
          <Graph title={t('Цифровизация')}>
            <Digitization />
          </Graph>
        </div>
      </Container>
    </section>
  )
}

export default Infographics