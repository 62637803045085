// extracted by mini-css-extract-plugin
export var mt7 = "c_t";
export var mt8 = "c_v";
export var mt2_25 = "c_w";
export var mt2_5 = "c_x";
export var mt1_5 = "c_y";
export var mt1_375 = "c_z";
export var mt0_5 = "c_B";
export var mt1 = "c_C";
export var mb5_75 = "c_D";
export var lh125 = "c_F";