import * as React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import slickSettings from './slickSettings'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './assets/css/index.module.css'
import { Container } from '../../common'
import { Advantage } from '..'
import { LightbulbIcon, DiagramIcon, HatIcon, HiveIcon, SquaresIcon } from './images' 
import { Bean } from '../../bean'

interface Props extends Bean {}

const Advantages: React.FC<Props> = ({
  className,
  style,
  children,
}) => {
  return (
    <section className={`${styles.root} ${className}`} style={style}>
      <StaticImage
        src="./assets/images/bg.png"
        alt="Advantages background"
        placeholder="blurred"
        className={styles.bg}
      />
      <Container className={styles.grid}>
        <div className={styles.wrapper}>
          <Slider {...slickSettings}>
            <div>
              <Advantage icon={<LightbulbIcon />}>
                <Trans>GLOBAL FACTORING NETWORK является fintech-платформой, в котором в полной мере реализован цифровой клиентский путь с использованием in-house решений, а так же высококачественных digital-продуктов от партнеров.</Trans>
              </Advantage>
            </div>
            <div>
              <Advantage icon={<SquaresIcon />}>
                <Trans>Использование онлайн-онбординга предоставляет нашим клиентам - малому и среднему бизнесу быстрый индивидуальный сервис на территории любого субъекта РФ.</Trans>
              </Advantage>
            </div>
            <div>
              <Advantage icon={<HiveIcon />}>
                <Trans>Мы активно применяем в работе BlockChain-технологии и юридически значимый электронный документооборот, что позволяет нашим партнерам получать все преимущества факторинга в безбумажной электронной среде.</Trans>
              </Advantage>
            </div>
            <div>
              <Advantage icon={<DiagramIcon />}>
                <Trans>Мы исповедуем paper-free философию и придерживаемся позиций социально ответственного бизнеса.</Trans>
              </Advantage>
            </div>
            <div>
              <Advantage icon={<HatIcon />}>
                <Trans>Нашими дебиторами являются крупные финансового устойчивые федеральные ритейлеры (Х5 Retail, ТС Магнит, ТС Верный, ТС Светофор, ТС Монетка, ТС Дикси Юг), а также большое количество торговых сетей из сегмента Do-It-Yourself.</Trans>
              </Advantage>
            </div>
          </Slider>
        </div>
      </Container>
    </section>
  )
}


export default Advantages