import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Heading } from '../../common'
import * as styles from './assets/css/index.module.css'
import ListItemIcon from './assets/images/list-item-icon.svg'
import BondsFigure from './assets/images/bonds-figure.svg'
import { Trans } from 'react-i18next'
import { Bean } from '../../bean'

interface Props extends Bean {}

const Bonds: React.FC<Props> = ({
  className = '',
  style = {},
  children,
}) => (
  <section className={`${styles.root} ${className}`} style={style}>
    <div className={styles.bg}>
      <StaticImage
          src="./assets/images/bonds-bg.jpg"
          alt="A dinosaur"
          placeholder="blurred"
        />
    </div>
    <Container>
      <Heading className={styles.heading}>
        <Trans>Параметры выпуска облигаций</Trans>
      </Heading>
      <div className={styles.grid}>
        <BondsFigure className={styles.figure} />
        <ul className={styles.list}>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>Стоимость одной облигации: 1000 рублей</Trans>
            </span>
          </li>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>Фиксированный доход по облигации: от 12% до 13% годовых</Trans>
            </span>
          </li>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>Ежеквартальные  выплаты купонного дохода</Trans>
            </span>
          </li>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>Наличие оферты до срока погашения облигаций</Trans>
            </span>
          </li>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>Простой способ приобретения: OTC Board</Trans>
            </span>
          </li>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>Выплачено купонного дохода: 94,6 млн рублей</Trans>
            </span>
          </li>
          <li className={styles.item}>
            <ListItemIcon />
            <span>
              <Trans>8 успешных оферт</Trans>
            </span>
          </li>
        </ul>
      </div>
    </Container>
  </section>
)

export default Bonds