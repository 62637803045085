const documents = [
  {
    title: 'Сообщение о дате начала размещения коммерческих облигаций',
    createdAt: '2018-05-15T00:00:01Z',
    category: 'Архив выпусков',
    link: 'https://www.factoring-network.ru/upload/iblock/bd2/bd2182e8c6282cebc07d72644d59fb26.PDF',
    deleted: false,
  },
  {
    title: 'Сообщение об установлении ставок 1-го и 2-го купонов',
    createdAt: '2018-05-16T00:00:01Z',
    category: 'Архив выпусков',
    link: 'https://www.factoring-network.ru/upload/iblock/57d/57dc5dfbcb0dd1eb54e1cb1358538f3a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам',
    createdAt: '2018-11-20T00:00:01Z',
    category: 'Архив выпусков',
    link: 'https://www.factoring-network.ru/upload/iblock/549/54913c2a1605a694ee2602a2ac39ab05.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента',
    createdAt: '2019-05-22T00:00:01Z',
    category: 'Архив выпусков',
    link: 'https://www.factoring-network.ru/upload/iblock/408/408d7fac3b034d871ff1e224994467aa.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о погашении эмиссионных ценных бумаг эмитента',
    createdAt: '2019-05-22T00:00:01Z',
    category: 'Архив выпусков',
    link: 'https://www.factoring-network.ru/upload/iblock/322/32252b9b817f8f232be014617af10c48.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о присвоении выпуску эмиссионных ценных бумаг идентификационного номера КО-П03',
    createdAt: '2019-06-07T00:00:01Z',
    category: 'Данные о новых выпусках',
    link: 'https://www.factoring-network.ru/upload/iblock/049/049dd5497ded88f1b59727b5a5706749.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о присвоении выпуску эмиссионных ценных бумаг идентификационного номера КО-П02',
    createdAt: '2019-06-07T00:00:01Z',
    category: 'Данные о новых выпусках',
    link: 'https://www.factoring-network.ru/upload/iblock/236/2368646a45a0114b21bb925c8df7475b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о присвоении выпуску эмиссионных ценных бумаг идентификационного номера КО-П04',
    createdAt: '2020-03-20T00:00:01Z',
    category: 'Данные о новых выпусках',
    link: 'https://www.factoring-network.ru/upload/iblock/da5/da510e44d53b75cc671cb65653b6741d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о присвоении выпуску эмиссионных ценных бумаг идентификационного номера КО-П05',
    createdAt: '2020-06-18T00:00:01Z',
    category: 'Данные о новых выпусках',
    link: 'https://www.factoring-network.ru/upload/iblock/559/559139e095cd3f96cb15e0d3885f859d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о присвоении выпуску эмиссионных ценных бумаг идентификационного номера КО-П06',
    createdAt: '2020-06-18T00:00:01Z',
    category: 'Данные о новых выпусках',
    link: 'https://www.factoring-network.ru/upload/iblock/476/4769ec85639695e1711304a99f145f4f.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность за 2019 год',
    createdAt: '2020-01-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/06d/06da2b9a665d49f8bb36cd300d1c3fe5.pdf',
    deleted: false,
  },
  {
    title: 'Аудиторское заключение за 2019 год',
    createdAt: '2020-01-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/367/367520e129a10f7fb8061f6a776effdb.pdf',
    deleted: false,
  },
  {
    title: 'Аудиторское заключение за 2019 год',
    createdAt: '2020-01-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/367/367520e129a10f7fb8061f6a776effdb.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность за 2020 год',
    createdAt: '2021-01-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/d9b/d9b9721a1777369ec0828d328463912e.pdf',
    deleted: false,
  },
  {
    title: 'Аудиторское заключение за 2020 год',
    createdAt: '2021-01-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/82d/82d23e2ec5691cfd9739f96bbaffe5a4.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность за 1 кв. 2021 года',
    createdAt: '2021-04-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/6e9/6e9b5b341dac2e305ae3c75c85fdca3d.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль за 1 кв. 2021 года',
    createdAt: '2021-04-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/6cd/6cd0af2223a667cb5a757d7d22ea3497.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность за 2 кв. 2021 года',
    createdAt: '2021-07-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/dcd/dcd7eaaebe6260b97a16e2e2028f8561.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль за 2 кв. 2021 года',
    createdAt: '2021-07-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/3c8/3c8eedbffecef7c610f6dfa0edc923a2.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность за 3 кв. 2021 года',
    createdAt: '2021-10-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/420/420f0fdd03ff35b7fd67c43a4bc1aa35.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль за 3 кв. 2021 года',
    createdAt: '2021-10-01T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/1ad/1ad04bf9f87b70a735d9e16944e0fe4c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П02',
    createdAt: '2019-07-01T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/032/0326a8c4aaab7d1181f695438a7e52c0.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П03',
    createdAt: '2019-08-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/779/779e2bad39366acd69318234222b69c0.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П02',
    createdAt: '2019-12-30T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/779/779e2bad39366acd69318234222b69c0.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента',
    createdAt: '2020-02-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/96d/96d950651d1c0bdddb5376e5c3f546a4.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П04',
    createdAt: '2020-03-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/110/1103cc59cd88022047f01b5e40e1af6f.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П02',
    createdAt: '2020-06-05T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/02f/02facbd5a47f7a815cca8e3a79018c45.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П02',
    createdAt: '2020-06-05T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/57e/57e1befe99d1aef86c1822e2bea46df0.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П05',
    createdAt: '2020-06-18T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/434/4343e95ec7c9e7485eced8da188f159c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П06',
    createdAt: '2020-06-18T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/428/4280d598a2bf31216624921c9e278cde.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2020-06-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/674/67405e02720da2ec7242576b96c89960.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П02',
    createdAt: '2020-06-29T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/dd2/dd22f9318d5aea5e6e79abf0cabd4f8e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о завершении периода оферты КО-П02',
    createdAt: '2020-06-29T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/e0d/e0db41b31f800a16d5e9cbf97692dd79.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П02',
    createdAt: '2020-07-03T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/260/26030c6fad7d104e0551550f69d7e710.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П03',
    createdAt: '2020-08-04T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/443/443f056c55eaf0899c69f0f7d9568f31.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям_КО-П03',
    createdAt: '2020-08-04T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/5da/5dae49222af2bfa1a798e31b4c55f0ee.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о присвоении выпуску ценных бумаг идентификационного номера КО-П07',
    createdAt: '2020-08-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/829/829770ce137293d3eefeb6d57461048b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П07',
    createdAt: '2020-08-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/68c/68ccf6e8b8439353ba67083d67233c99.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о завершении периода оферты КО-П03',
    createdAt: '2020-08-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/a62/a6288ed77cca05da5921d96c50b3e7dc.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П03',
    createdAt: '2020-08-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/91d/91dfaa442056095e433f4190a6942cda.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П03',
    createdAt: '2020-08-26T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/b06/b06020c54d5b8f7010b53ff39363bde9.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2020-09-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/835/835e9418f02418eb6396a4969d9d957a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2020-09-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/591/591a9060ffb21f43b773a4e81f543a3d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2020-09-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/f1b/f1bcd25a1f1a533d150fe6980372ecf8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П07',
    createdAt: '2020-11-24T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/e99/e9936af4d7013e1f71e835dd4b8ec5bd.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска эмиссионных ценных бумаг КО-П002-01',
    createdAt: '2020-12-15T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/456/456e21efa8505cfb5c429ba1bf3102ee.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска эмиссионных ценных бумаг КО-П002-02',
    createdAt: '2020-12-15T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/06e/06e59de408149304ec1c1bca30906fa9.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П002-01',
    createdAt: '2020-12-16T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/7a6/7a6ce9b0a425b182dc6d476c1d710238.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2020-12-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/a68/a686ffa333ed0c51c7854b0f53cf481b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2020-12-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/9ee/9ee60a7fa0609f5941cf380f7ad32e25.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2020-12-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/d7e/d7e9c5cf53ab8b604f3906661a35a777.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П02',
    createdAt: '2020-12-28T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/e41/e41ea5945d6ad87b970d62dfea5b0bdf.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-01-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/36a/36a778207f165b275e87e50156263890.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П002-02',
    createdAt: '2021-02-18T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/0a4/0a45744d2768a1baf8cf811ce2785130.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-02-19T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/e8b/e8b080229105848f6faf7e680407724b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента_КО-П03',
    createdAt: '2021-02-19T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/9ac/9ac115ea8f188b51338e84968ed8001f.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П07',
    createdAt: '2021-02-24T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/aaa/aaaa557486bcfb66e449337d27c3ace2.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П04',
    createdAt: '2021-03-05T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/05e/05e8db874a655858979b23a30498194e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П04',
    createdAt: '2021-03-05T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/c2b/c2b38d313fb81118b0edf875f9e38a7b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-03-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/215/2153aa379df396f8bf094edf138362f8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2021-03-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/9d9/9d91746b6fb6c08430e223549570982c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2021-03-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/7ea/7ea63a9d272b0fcc5289b426a0c8334d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2021-03-24T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/8ee/8ee3a7418787054b68917e00e5770513.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-03-27T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/116/116db47d138cfc2e198e1a7f896944ff.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П04',
    createdAt: '2021-03-29T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/4ed/4ed535be69b8f5abfbcf706f4e95a38c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-04-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/922/922ebf806c094cb64f2e8c12e5b2e573.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента_КО-П002-02',
    createdAt: '2021-04-26T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/3a6/3a69666fe6e91ad909eb981cf7fa1c60.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-05-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П07',
    createdAt: '2021-05-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/e3d/e3dc3122e099412bc280e258a15d3c32.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-05-26T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/c18/c183028c6cc188838b08916e0ef49a13.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П02',
    createdAt: '2021-06-02T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П05',
    createdAt: '2021-06-02T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/f07/f07708391029c728c32c7c032e6e4912.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П06',
    createdAt: '2021-06-02T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/978/978f3cfd91dbfd69d8e85e86bddd628e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П02',
    createdAt: '2021-06-02T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/acf/acf6287bcec02726f3f8c61c82d09cbc.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П05',
    createdAt: '2021-06-02T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/ff0/ff0080357768b6e19a4d79f052d41708.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П06',
    createdAt: '2021-06-02T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/eb8/eb8bcb5dc3a4c72280693043b0dd3442.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-06-19T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/39e/39e367dd0cc2bc6edc6df0553a011135.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2021-06-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/892/89212442b5868f5c29356d718882bdcd.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2021-06-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/eb8/eb85370be19a4c581ff2fafc1d6135ec.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2021-06-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/2fa/2fad686ca6f927285435c2340b66c41c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-06-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/316/316ab3be82a8fa3501cf673410c3ff13.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П05',
    createdAt: '2021-06-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/d17/d17bb791d153afe3050e6e102e249e13.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П06',
    createdAt: '2021-06-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/3e9/3e97adbd3306e4a086594d883ff2bf68.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П02',
    createdAt: '2021-06-28T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/c90/c90e9d41bab690b46a3c46486618cf91.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П02',
    createdAt: '2021-07-01T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/06e/06e03e0358685cf7ef2fffc7fd6fe4b3.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-07-19T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/1da/1da381aff6dd2428ee4ba8284f6310a8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-07-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/47f/47fc56f2a1132f10e621a28929faa101.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П03',
    createdAt: '2021-08-04T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/368/36866e405ba9f016dfc8ef5723dcabca.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П03',
    createdAt: '2021-08-04T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/209/20918825a423a7d07a2a9bad62560427.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П07',
    createdAt: '2021-08-05T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/641/641ba3b6886d58f5bac7df9a456a03be.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П07',
    createdAt: '2021-08-05T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/d17/d1720835f73adeba615b1d3741b8de84.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-08-18T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/c6e/c6e0171aca218967d5d04d96ec820d4a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П03',
    createdAt: '2021-08-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/ed5/ed51061ade8d54a8c3156336a2e4b522.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-08-24T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/50b/50ba371398c9a261462d126dce0100cf.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П07',
    createdAt: '2021-08-24T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/5d6/5d68e5705d4cc4ac0cd42012d32c192d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П03',
    createdAt: '2021-08-25T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/4cc/4cc322980732ce0f73052b692d05bed4.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П07',
    createdAt: '2021-08-27T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/dd6/dd674b51cfbc7dd6ac2255ca7a04b792.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-09-17T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/1fe/1fe1d8bcd27f7ddc3dbd074ea22d5ecc.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2021-09-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/d4e/d4e381a92bfbd58547e5ffa30266a0b5.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2021-09-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/c9d/c9dad19cc48c77cfdef5603e71ffa9ad.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2021-09-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/fe7/fe72cb17dadc95b84431d970c8b9b9c5.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-09-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/3a4/3a46371163b0c728d875a4bfa28246c7.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-10-17T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/8dc/8dc140d737d40169a6bd21d712364382.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-10-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/5f6/5f69774b5840aab1bed0b814c4e97044.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-11-16T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/1ed/1ed5ef59040b5d7dbf8444d955532712.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-11-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/842/842d6424c8221ad2ced38f200232b01b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П07',
    createdAt: '2021-11-23T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/86d/86de0f6fbcc6691014cdaf98adbbb876.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска эмиссионных ценных бумаг КО-П002-03',
    createdAt: '2021-12-06T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/03c/03ce9f98430ec7e234ab7c2b34962747.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска эмиссионных ценных бумаг КО-П002-04',
    createdAt: '2021-12-06T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/f22/f221ba8f2423f254e8a8d68a0f5460d7.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска эмиссионных ценных бумаг КО-П002-05',
    createdAt: '2021-12-06T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/26d/26db6e1bf9465cea5c08057d62543ef2.pdf',
    deleted: false,
  },
  {
    title: 'Решение о выпуске ценных бумаг КО-П002-03',
    createdAt: '2021-12-06T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/9d8/9d8668dc438249a25d53c8cc6c86fa2e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П002-03',
    createdAt: '2021-12-09T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/b8d/b8dcbffc999eb7fdb2a8f7f6371b8005.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2021-12-16T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/3b8/3b8e6f4fb2f23a03d5d9a0aa00dd4563.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2021-12-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/891/891b65c427282539af2c7d91a7877abc.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2021-12-21T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/0c6/0c649ef36c88988a96363692779a305d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2021-12-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/4a3/4a3b68695c5b515718493ae1bdcd6633.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2021-12-22T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/f34/f34c3156d025749e9e55cec27f1f331a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П02',
    createdAt: '2021-12-27T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/070/07065077a75e76a8b75453d57385f2ea.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность за 2021 год',
    createdAt: '2022-03-25T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/bb9/bb999084bd93983e8d673abcf0a1d6da.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль за 2021 год',
    createdAt: '2022-03-25T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/f1d/f1d753aeb0ad11dd7a657815e2623d50.pdf',
    deleted: false,
  },
  {
    title: 'Презентация для инвесторов - 2021',
    createdAt: '2021-09-08T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/ff5/ff546132d56ef8ac239c8a0248379e41.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о погашении эмиссионных ценных бумаг эмитента КО-П02',
    createdAt: '2022-06-24T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/a60/a60686ab5746c9450ac81b8a0e3d63c1.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П05',
    createdAt: '2022-06-27T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/f52/f52988483f273ddb0b7a124f4ebad3cc.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-03',
    createdAt: '2022-07-13T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/184/18492079f05e395cfd1856fce0feaa33.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2022-07-14T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/c25/c25aebb85819e3df26c366c4eca01bf5.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2022-07-20T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/a1a/a1a2e7c52c9753202c52a09377e690d6.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность 2 кв. 2022 года',
    createdAt: '2022-07-28T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/e69/e69a649d1eb873780446b9cf1e34a8f4.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль 2 кв. 2022 года',
    createdAt: '2022-07-28T00:00:01Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/f01/f01e659cfd35a44adaa8225ab819c007.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения ценных бумаг КО-П002-04',
    createdAt: '2022-08-15T00:00:01Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/2ce/2cefbfcdc9edafb7cdb1f8c43ab1af23.pdf',
    deleted: false,
  },
  {
    title: 'Аудиторское заключение за 2021 год',
    createdAt: '2022-04-18T00:00:00Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/17f/17f38a76f24e6422a398b0c90b88b125.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность 3 кв. 2022 года',
    createdAt: '2022-10-28T00:00:00Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/049/049a4e84befdefe9a3e12a800be58f46.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль 3 кв. 2022 года',
    createdAt: '2022-10-28T00:00:00Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/263/263a5feb154cddf42844d3fc9de6975b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-03',
    createdAt: '2022-09-11T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/e99/e9998ae7a8adfcd174bf9d94a782b726.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2022-09-12T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/fe7/fe7f5a0f7ff6a492d62612178efc241d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2022-09-19T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/a7f/a7f2af979ef5f02add1734a25b913b92.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2022-09-20T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/b48/b48e06f5763556500a660a93e4648c0c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2022-09-20T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/03a/03a2e01c020e85f1619e61908af65c97.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-04',
    createdAt: '2022-09-21T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/cef/cef6ab20b2ffe1354ca6b00a0c8bce32.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2022-09-21T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/b52/b5284623475c5cab26c6b75e74047f66.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-03',
    createdAt: '2022-10-11T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/ea6/ea64696c373e1273f26967389af1d30d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2022-10-12T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/8b4/8b486856f9841acfe40ab7cef14ed598.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2022-10-18T00:00:00Z',
    category: 'Новые выпуски',
    link: 'https://www.factoring-network.ru/upload/iblock/eb6/eb6b19d5581dec213091c06d2cf6c58f.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации Программы облигаций 003P',
    createdAt: '2022-11-10T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/e65/e65bc234afa1cdec5be4295603758fff.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации Устав',
    createdAt: '2022-11-17T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/d02/d02ac1c5960b3a9289093d4a2326f2db.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации Отчетность',
    createdAt: '2022-11-18T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/f0d/f0d05c1ae6a8e7486c21b165d03e90cb.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации Перечень',
    createdAt: '2022-11-18T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/30e/30e03a9dcbfc200a30ac455d642bd7c6.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации Программа',
    createdAt: '2022-11-18T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/a74/a74979d40bf9fc69b0ffa0e8b0a5b51d.pdf',
    deleted: false,
  },
  {
    title: 'Перечень инсайдерской информации',
    createdAt: '2022-11-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/60f/60f5cfc594b2df8bf53c3efe2e1d8e92.pdf',
    deleted: false,
  },
  {
    title: 'Программа биржевых облигаций',
    createdAt: '2022-11-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/82f/82fe0685b63fccf28280eadf781f5b3f.pdf',
    deleted: false,
  },
  {
    title: 'Решение о выпуске ценных бумаг БО-01-001Р',
    createdAt: '2022-11-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/',
    deleted: false,
  },
  {
    title: 'Сообщение о включении в список ценных бумаг БО-01-001Р',
    createdAt: '2022-11-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/9c3/9c37314be912ba6e55ceec62c042b4f8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации Решение о выпуске',
    createdAt: '2022-11-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/899/899c08f882f7dc0a1044aadbbf626bdd.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска ценных бумаг БО-01-001Р',
    createdAt: '2022-11-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/3c8/3c8c8454a71dd4fe3e2711bbb2c7cc21.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения БО-01-001Р',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/3b5/3b5331a5a8ea6b40ac3d8e58ef7d67e3.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о начисленных доходах БО-01-001Р',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/e91/e91fca99403b103c7388ce68b27e9488.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об андеррайтере БО-01-001Р',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/6e1/6e1b02ae9e98ca3d735591c69286ed2e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о начисленных доходах по ценным бумагам БО-01-001P',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/eaa/eaaa490fabcf4da84822205ff190688d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации ДСУР ИМ БО-01-001P',
    createdAt: '2022-11-30T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/e4f/e4f5ff63878ed4eb3d36935bc0ec9ce2.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о завершении размещения ценных бумаг БО-01-001P',
    createdAt: '2022-12-02T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/c68/c682c075cd121c4a2f43079a6f19743c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о договоре ММ БО-01-001P',
    createdAt: '2022-12-06T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/c5c/c5c27224d8b2d379235ccc87b2ea1e9b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П002-01',
    createdAt: '2022-12-16T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/039/0399ca23d364b019e4d94e57273c35d8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П002-03',
    createdAt: '2022-12-16T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/7d4/7d48b8714411aee3287bd9be3f6594ac.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П002-01',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/c30/c30e3a0bdc71617395acb6255d862856.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П002-03',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/d67/d67e622c1389223815c0a82cead7f3ad.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П002-01',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/880/8809bef61a8010f988fb6b3db74099b0.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П002-03',
    createdAt: '2022-11-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/37e/37e1b7d983c7fff0c1fbf6b0d0183d76.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплате купонного дохода БО-01-001P',
    createdAt: '2023-01-09T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/4cb/4cbed3163a877dcb2761860dd87e2e2a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о проведении ВОСУ',
    createdAt: '2022-12-26T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/e51/e51570fb52f6ee3cde5635f8fe0ec918.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2022-12-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/5e6/5e6becc4c27a69c8129a2eb4a679a1d0.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2022-12-20T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/de2/de276fbd6381f5713e81460a7a81c88a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2022-12-20T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/733/7332b46e109d6f1c92006aca74cf6ac8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-04',
    createdAt: '2022-12-20T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/76e/76e11308c329ddc6e4988c26f0013eda.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2022-12-17T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/28d/28d54cf2a8f23141cbfa16d8ab9d88c4.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П002-03',
    createdAt: '2022-12-16T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/7d4/7d48b8714411aee3287bd9be3f6594ac.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П002-01',
    createdAt: '2022-12-16T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/039/0399ca23d364b019e4d94e57273c35d8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2022-12-11T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/8bb/8bb5dc4e6e58827054578ef2e3191a93.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-03',
    createdAt: '2022-12-10T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/dfc/dfc27d0e288f71e8657553f533f4fb24.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплате купонного дохода БО-01-001P',
    createdAt: '2023-01-09T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/4cb/4cbed3163a877dcb2761860dd87e2e2a.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2023-01-10T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/949/949fae7cc3281d918d89155bf4aafd32.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2023-01-16T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/6dd/6dd1266e1b6ac62aa6b3d99b32bceff5.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-04',
    createdAt: '2023-01-19T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/2e4/2e4a35b2d23efa80f443d161daa4dff5.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплате купонного дохода БО-01-001P',
    createdAt: '2023-01-30T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/e2b/e2b4c6aa79d45feb9d80bad1a7784b9c.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-03',
    createdAt: '2023-02-08T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/c36/c3623124335309e4b5935e050483c32d.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2023-02-09T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/55d/55dc862b75551652a50b4c6110721be8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2023-02-15T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/834/834a9bdd4735be0e4d2566378caefd9e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-04',
    createdAt: '2023-02-18T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/b6c/b6c8b40a065e8549d1d5450e7f76e6cb.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П07',
    createdAt: '2023-02-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/82c/82c3d76d4b645dfab53c2defcf837189.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплате купонного дохода БО-01-001P',
    createdAt: '2023-03-01T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/bb7/bb742f7f91bd082f2685f0cf4a80ef6b.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П02',
    createdAt: '2023-03-06T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/b87/b87aae398301d5088db91fa7bf103c1f.pdf',
    deleted: false,
  },
  {
    title: 'Бухгалтерская отчетность 2022 год',
    createdAt: '2023-03-19T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/728/728fa4d56f7e78c19196b3ca730a49d7.pdf',
    deleted: false,
  },
  {
    title: 'Налоговая декларация по налогу на прибыль за 2022 год',
    createdAt: '2023-03-19T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/222/2220d33acdd7d0daaf2899e1e42d1020.pdf',
    deleted: false,
  },
  {
    title: 'Решение о выпуске ценных бумаг БО-02-001Р',
    createdAt: '2023-03-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/3bf/3bf1edccc98506a043de16b286b66a80.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о включении в список ценных бумаг БО-02-001Р',
    createdAt: '2023-03-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/4d8/4d8aa95af5a9387269107ab01088f2db.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации Решение о выпуске',
    createdAt: '2023-03-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/586/586ac664c271c297905d7d05ee1859a7.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о регистрации выпуска ценных бумаг БО-02-001Р',
    createdAt: '2023-03-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/3ad/3adaa5590794ffa0bfa8ccfb18ecc819.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о дате начала размещения БО-02-001Р',
    createdAt: '2023-03-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/685/685036796bf5a217d1f37656c72c5cc8.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о начисленных доходах БО-02-001Р',
    createdAt: '2023-03-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/774/774eb399efc82f09b839c7355702532e.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об андеррайтере БО-02-001Р',
    createdAt: '2023-03-23T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/eaf/eaf1661173336d874651a470bc5fa39e.pdf',
    deleted: false,
  },
  {
    title: 'ДСУР БО-02-001P',
    createdAt: '2023-03-27T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/a1e/a1e386c07c131897e4b10a3476f14f53.pdf',
    deleted: false,
  },
  {
    title: 'ИМ БО-02-001P',
    createdAt: '2023-03-27T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/2d8/2d82927e2dcdab69c355df8ab9d63712.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации ДСУР ИМ',
    createdAt: '2023-03-27T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/714/7144c76269b60255e9118c2b65c613c4.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выполнении обязательств эмитента в рамках оферты КО-П04',
    createdAt: '2023-03-28T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/b4c/b4ce2ad1fdbc61c456d5210c58e7f9bd.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-01',
    createdAt: '2023-03-11T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/19d/19d2bff1fd5f09bf678a99d962482f09.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-02',
    createdAt: '2023-03-17T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/98f/98fdfc67afbe5ddfb4c31d56435a1eca.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П002-03',
    createdAt: '2023-03-10T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/11c/11c6814aafabf7ed14bf896962692290.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П04',
    createdAt: '2023-03-22T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/f53/f530b7d67cd5843a4a72d80c8b041a49.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П05',
    createdAt: '2023-03-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/39f/39f40a82d3254e4f7dc6ca4eff032ec5.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплаченных доходах по эмиссионным ценным бумагам эмитента КО-П06',
    createdAt: '2023-03-21T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/1fd/1fd40b92b8c98e7345729fa5950c51cc.pdf',
    deleted: false,
  },
  {
    title: 'Аудиторское заключение за 2022 год',
    createdAt: '2023-04-27T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/378/xnbw4blstlwp3j25ycidlm15fbb88cug.pdf',
    deleted: false,
  },
  {
    title: 'Отчет эмитента за 2022 год',
    createdAt: '2023-04-27T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/ae0/wo9cs71pz2sowv3sb4p6yhi3d4rs4dui.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о порядке доступа к инсайдерской информации: Отчетность 1 кв. 2023',
    createdAt: '2023-05-15T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/1da/1qt3f03vtpscloaq8l1nn4xwtixgc5lx.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о повышении рейтинга',
    createdAt: '2023-05-26T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/f7f/4ibyc0eq3lqxqsz6z2hzrhhux4gfwzzr.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об определении процента (купона) по облигациям КО-П06',
    createdAt: '2023-05-26T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/304/nobt6k197xi34tgx7qx1s8ctkn3az9io.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение об оферте КО-П06',
    createdAt: '2023-05-26T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/091/0g1z9eoyrb1ek0zfr1qnxm2gd0z1abbh.pdf',
    deleted: false,
  },
  {
    title: 'Сообщение о выплате купонного дохода БО-02-001P',
    createdAt: '2023-05-29T00:00:00Z',
    category: 'Раскрытие информации',
    link: 'https://www.factoring-network.ru/upload/iblock/3b5/cc624oy52y6sn4y9s3w7brm0t8jf9ze2.pdf',
    deleted: false,
  },
  {
    title: 'Аудиторское заключение за 2022 год',
    createdAt: '2023-05-25T00:00:00Z',
    category: 'Актуальная финансовая отчётность',
    link: 'https://www.factoring-network.ru/upload/iblock/108/nc8kjtxv76wt0yunrrcsl798pvsfkk7d.pdf',
    deleted: false,
  },

]

export default documents