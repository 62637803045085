import React from 'react'
import Select from 'react-select'
import { Bean } from '../../bean'
import * as styles from './assets/css/language-switcher.module.css'
import RussianFlagIcon from './assets/images/flag-russia.svg'
import UsaFlagIcon from './assets/images/flag-usa.svg'
import JapanFlagIcon from './assets/images/flag-japan.svg'
import { I18nextContext, useI18next } from 'gatsby-plugin-react-i18next'

interface Lang {
  code: string
  title: React.ReactNode
  flag: React.ReactNode
}

interface Props extends Bean {
  langs?: Array<Lang>
}

const LanguageSwitcher: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  langs,
}) => {
  const {languages, changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const currentLanguage = context.language
  const options = [
    { value: 'ru', label: <div className={styles.label}><RussianFlagIcon /><span>Ru</span> </div> },
    { value: 'en', label: <div className={styles.label}><UsaFlagIcon /><span>En</span> </div> },
    { value: 'ja', label: <div className={styles.label}><JapanFlagIcon /><span>Jp</span> </div> },
  ]
  const getDefaultValue = (language: string) => options.find(e => e.value === language);
  const onChangeHandler = (newOption) => changeLanguage(newOption.value)
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <Select
        defaultValue={getDefaultValue(currentLanguage)}
        options={options}
        className={styles.select}
        onChange={onChangeHandler}
      />
    </div>
  )
}

export default LanguageSwitcher