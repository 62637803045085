import React from 'react'
import DocumentIcon from './assets/images/document.svg'
import { Bean } from '../../bean'
import * as styles from './assets/css/document.module.css'

export interface Document extends Bean {
  title: React.ReactNode
  createdAt: Date | string
  category: string
  link: string
  deleted?: boolean
}

const Document: React.FC<Document> = ({
  children,
  className = '',
  style,
  title,
  createdAt,
  category,
  link,
  deleted,
}) => {
  return (
    <a href={link} target="_blank" className={styles.root}>
      <div className={styles.icon}><DocumentIcon /></div>
      <div className={styles.title}>{title}</div>
    </a>
  )
}

export default Document