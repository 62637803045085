import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

const Finance: React.FC = ({}) => (
  <StaticImage
    src="./assets/images/finance.png"
    alt="Finance icon"
    placeholder="blurred"
  />
)
const Supplies: React.FC = ({}) => (
  <StaticImage
    src="./assets/images/supplies.png"
    alt="Finance icon"
    placeholder="blurred"
  />
)
const Top: React.FC = ({}) => (
  <StaticImage
    src="./assets/images/top.png"
    alt="Finance icon"
    placeholder="blurred"
  />
)
const Cagr: React.FC = ({}) => (
  <StaticImage
    src="./assets/images/cagr.png"
    alt="Finance icon"
    placeholder="blurred"
  />
)

export { Finance, Supplies, Top, Cagr }