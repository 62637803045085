import { v4 as uuid } from 'uuid'
import { FilterItem, FilterState } from './types'
import { Action, Type } from './actions'

const reducer = (state: FilterState, action: Action) => {
  const { payload, type } = action
  switch (type) {
    case Type.Added: {
      const filter = { id: uuid(), ...payload }
      return [...state, filter]
    }
    case Type.Activated: {
      return state.map((item: FilterItem) => {
        if (item.id === payload.id) {
          return {
            ...item,
            active: true
          }
        }
        return item
      })
    }
    case Type.Deactivated: {
      return state.map((item: FilterItem) => {
        if (item.id === payload.id) {
          return {
            ...item,
            active: false
          }
        }
        return item
      })
    }
    case Type.Toggled: {
      return state.map((item: FilterItem) => {
        if (item.id === payload.id) {
          return {
            ...item,
            active: !item.active
          }
        }
        return item
      })
    }
    default:
      return state
  }
}

export default reducer