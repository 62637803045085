import React, { useState } from 'react'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Container, Heading, Paragraph } from '../../common'
import * as styles from './assets/css/index.module.css'
import * as utilities from '../../../assets/css/utilities.module.css'
import FaqHeader from './assets/images/faq-header.svg'
import { Bean } from '../../bean'
import { QA } from '..'
import { Link } from 'react-scroll'

interface Props extends Bean {}

const Frequently: React.FC<Props> = ({
  className = '',
  style = {},
  children,
}) => {
  const { t } = useTranslation()
  const data = [
    {
      title: t('Как заработать на облигациях?'),
      content: t(`Как заработать на облигациях? Ответ`),
      category: t('Преимущества облигаций'),
    },
    {
      title: t('Какие преимущества у облигаций перед депозитом?'),
      content: t(`Какие преимущества у облигаций перед депозитом? Ответ`),
      category: t('Преимущества облигаций'),
    },
    {
      title: t('Какие преимущества у облигаций перед акциями?'),
      content: t(`Какие преимущества у облигаций перед акциями? Ответ`),
      category: t('Преимущества облигаций'),
    },
    {
      title: t('Как приобрести облигации?'),
      content: t(`Как приобрести облигации? Ответ`),
      category: t('Приобретение и доход'),
    },
    
    {
      title: t('Кто такой эмитент облигаций?'),
      content: t(`Кто такой эмитент облигаций? Ответ`),
      category: t('Приобретение и доход'),
    },
    {
      title: t('Как выплачивается купонный доход по облигациям?'),
      content: t(`Как выплачивается купонный доход по облигациям? Ответ`),
      category: t('Приобретение и доход'),
    },
    {
      title: t('Взимаются ли комиссии?'),
      content: t(`Взимаются ли комиссии? Ответ`),
      category: t('Приобретение и доход'),
    },
    {
      title: t('Как и когда я смогу получить свои деньги при погашении облигаций?'),
      content: t(`Как и когда я смогу получить свои деньги при погашении облигаций? Ответ`),
      category: t('Приобретение и доход'),
    },
    {
      title: t('Что такое оферта?'),
      content: t(`Что такое оферта? Ответ`),
      category: t('Оферта'),
    },
    {
      title: t('Как понять, когда будет оферта и будет ли вообще?'),
      content: t(`Как понять, когда будет оферта и будет ли вообще? Ответ`),
      category: t('Оферта'),
    },
    {
      title: t('Где хранятся облигации?'),
      content: t(`Где хранятся облигации? Ответ`),
      category: t('Гарантии безопасности'),
    },
    {
      title: t('Откуда я буду знать, какие у меня облигации и сколько?'),
      content: t(`Откуда я буду знать, какие у меня облигации и сколько? Ответ`),
      category: t('Гарантии безопасности'),
    },
    {
      title: t('Какие есть риски?'),
      content: t(`Какие есть риски? Ответ`),
      category: t('Гарантии безопасности'),
    },
    {
      title: t('Должен ли я заплатить налоги?'),
      content: t(`Должен ли я заплатить налоги? Ответ`),
      category: t('Гарантии безопасности'),
    },
  ]
  const categories = [...new Set(data.map((item) => item.category))]
  const [currentCategory, setCurrentCategory] = useState(categories[0] || '')
  const [currentQuestion, setCurrentQuestion] = useState<number | undefined>()
  const setCurrentCategoryHandler = (category: string): void => {
    setCurrentQuestion(undefined)
    setCurrentCategory(category)
  }
  const setCurrentQuestionHandler = (question: number): void => {
    if (currentQuestion === question) {
      setCurrentQuestion(undefined)
    } else {
      setCurrentQuestion(question)
    }
  }
  return (
    <section className={`${styles.root} ${className}`} style={style}>
      <div className={styles.header}>
        <figure className={styles.figure}>
          <FaqHeader />
        </figure>
        <Container className={styles.grid}>
          <Link to="contacts" spy={true} hashSpy={true} smooth={true} className={styles.contact}>
            <Trans>Остались вопросы? Напишите нам</Trans>
          </Link>
          <Heading className={utilities.mt1}><Trans>Часто задаваемые вопросы</Trans></Heading>
          <Paragraph className={utilities.mt2_5}><Trans>Мы собрали для Вас список часто задаваемых вопросов и ответов на них</Trans></Paragraph>
        </Container>
      </div>
      <div className={styles.main}>
        <Container className={styles.faq}>
          <div className={styles.sidebar}>
            <Heading className={styles.heading}>{t('Меня интересует:')}</Heading>
            <ul className={styles.categories}>
              {
                categories.map((category) => (
                  <li>
                    <span
                      className={`${styles.category} ${(category === currentCategory) && styles.active}`}
                      onClick={() => setCurrentCategoryHandler(category)}
                    >{category}</span>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className={styles.qa}>
            {
              data.filter((item) => item.category === currentCategory).map((item, i) => (
                <QA
                  opened={(currentQuestion === i)}
                  question={`${i + 1}. ${item.title}`}
                  onClick={() => setCurrentQuestionHandler(i)}
                >{item.content}</QA>
              ))
            }
          </div>
        </Container>
      </div>
    </section>
  )
}

export default Frequently