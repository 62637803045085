export default {
  slidesToShow: 5,
  slidesToScroll: 0,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        arrows: false,
        className: "center",
        centerPadding: "2rem",
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 3,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2,
        initialSlide: 0,
        infinite: false,
      }
    },
    {
      breakpoint: 640,
      settings: {
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        initialSlide: 0,
      }
    },
    {
      breakpoint: 420,
      settings: {
        arrows: false,
        className: "center",
        centerMode: true,
        infinite: false,
        slidesToShow: 1,
        initialSlide: 1,
      }
    }
  ]
};