// extracted by mini-css-extract-plugin
export var root = "k_b";
export var nav = "k_T";
export var prev = "k_V";
export var next = "k_W";
export var hide = "k_X";
export var disabled = "k_Y";
export var icon = "k_H";
export var title = "k_Q";
export var wrapper = "k_r";
export var list = "k_Z";
export var closed = "k_0";
export var event = "k_1";
export var content = "k_p";
export var opened = "k_2";
export var paragraph = "k_N";