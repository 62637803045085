// extracted by mini-css-extract-plugin
export var root = "S_b";
export var header = "S_c";
export var actions = "S_ch";
export var back = "S_cj";
export var backCaption = "S_ck";
export var nav = "S_T";
export var pagination = "S_cl";
export var pageLink = "S_cm";
export var pageItem = "S_cn";
export var selected = "S_cp";
export var nextPage = "S_cq";
export var prevPage = "S_cr";
export var disabled = "S_Y";
export var notFoundText = "S_cs";
export var captionBack = "S_ct";
export var search = "S_cv";
export var posts = "S_cw";
export var wrapper = "S_r";
export var post = "S_cx";
export var footer = "S_cy";