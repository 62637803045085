// extracted by mini-css-extract-plugin
export var root = "m_b";
export var small = "m_6";
export var link = "m_4";
export var grid = "m_j";
export var normal = "m_7";
export var meta = "m_8";
export var title = "m_Q";
export var excerpt = "m_9";
export var wrapper = "m_r";
export var image = "m_bb";
export var scale = "m_g";
export var layout = "m_q";