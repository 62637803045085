import * as React from 'react'
import * as styles from './assets/css/advantage.module.css'

export interface Advantage {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  icon: React.ReactNode
}

const Advantage: React.FC<Advantage> = ({
  children,
  className,
  style,
  icon,
}) => (
  <article className={`${styles.root} ${className}`} style={style}>
    <figure className={styles.shutter} />
    <div className={styles.icon}>{icon}</div>
    <p className={styles.text}>{children}</p>
    
  </article>
)

export default Advantage