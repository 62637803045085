import React, { useState } from 'react'
import { Post } from '..'
import { Bean } from '../../bean'
import { Button, Link, Paragraph } from '../../common'
import FwIcon from './assets/images/chevron-right.svg'
import * as styles from './assets/css/posts.module.css'

interface Props extends Bean {
  posts: any
  onArchiveShow: () => void
}

const Posts: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  posts,
  onArchiveShow
}) => {
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <div className={styles.grid}>
        { posts.map((post, i) => (
          <Post
            title={post.node.frontmatter.title}
            size={ i !== 0? 'small': 'normal' }
            featuredImage={post.node.frontmatter.featuredImage}
            url={post.node.id}
          >{post.node.frontmatter.excerpt}</Post>
        )) }
        <Button
          className={styles.button}
          onClick={onArchiveShow}
        >
          <div className={styles.caption}>
            <span>Все новости</span>
            <FwIcon />
          </div>
        </Button>
        {/* <Paragraph className={styles.links}>Следите за нашими новостями в <Link className={styles.link} href='https://www.facebook.com/Global-Factoring-Network-109929046042187' target='_blank'>Facebook</Link> и <Link className={styles.link} href='http://instagram.com/global_factoring_network' target='_blank'>Instagram</Link></Paragraph> */}
      </div>
    </div>
  )
}

export default Posts