// extracted by mini-css-extract-plugin
export var root = "H_b";
export var bg = "H_d";
export var image = "H_bb";
export var desktop = "H_bX";
export var extra = "H_bY";
export var wrapper = "H_r";
export var grid = "H_j";
export var burger = "H_bZ";
export var logo = "H_b0";
export var nav = "H_T";
export var items = "H_b1";
export var item = "H_bz";
export var external = "H_b2";
export var switcher = "H_b3";
export var link = "H_4";
export var cta = "H_b4";
export var heading = "H_n";
export var description = "H_R";
export var button = "H_k";
export var mwr = "H_b5";