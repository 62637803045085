import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import * as styles from './assets/css/archive.module.css'
import ArchiveItem from './archive-item'
import NextIcon from './assets/images/arrow-forward.svg'
import PrevIcon from './assets/images/arrow-back.svg'
import PrevDarkIcon from './assets/images/arrow-back-dark.svg'
import { Bean } from '../../bean'
import { Input } from '../../common'

export const excerpt = (str: string, limit: number = 92): string => {
  if (str.length > limit) {
      return str.substring(0, limit) + '...'
  }
  return str
}

export interface Tag {
  name: string
  slug: string
}

export interface Post {
  id: string
  title: string
  excerpt: string
  html: string
  date: string
  featuredImage?: string
  tags: {
    nodes: Tag[] | []
  }
}

export type Posts = Post[] | []

interface Props extends Bean {
  raw: any,
  onArchiveHide?: () => void
}

const ArchiveBlog: React.FC<Props> = ({
  raw,
  onArchiveHide
}) => {
  const PER_PAGE = 8
  const [search, setSearch] = useState('')
  // TODO Переписать фильтрацию
  const posts: Posts = raw.map((item) => {
    return {
      id: item.node.id,
      title: item.node.frontmatter.title,
      excerpt: item.node.frontmatter.excerpt,
      html: item.node.html,
      date: item.node.frontmatter.date,
      featuredImage: item.node.frontmatter.featuredImage,
      tags: item.node.frontmatter.tags
    }
  })
  const filteredPosts = posts
    .filter((item) => item.title.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      item.excerpt.trim().toLowerCase().includes(search.trim().toLowerCase()))
  const pageCount = Math.ceil(filteredPosts.length / PER_PAGE)
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + PER_PAGE;
  const currentItems = filteredPosts.slice(itemOffset, endOffset)
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * PER_PAGE) % filteredPosts.length;
    setItemOffset(newOffset);
  };
  const handleOnSearch = (nextValue: string): void => setSearch(nextValue)
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.actions}>
          <div className={styles.back} onClick={onArchiveHide}>
            <PrevDarkIcon />
            <span className={styles.backCaption}>Назад</span>
          </div>
        </div>
        <div className={styles.search}>
          <Input onChange={handleOnSearch} value={search} placeholder='Поиск' />
        </div>
      </div>
      <div className={styles.posts}>
        <div className={styles.wrapper}>
          {(filteredPosts.length !== 0) ? 
            currentItems.map((post) => (
              <div className={styles.post} key={post.id}>
                <ArchiveItem
                  date={post.date}
                  excerpt={excerpt(post.title)}
                  to={post.id.toString()}
                />
              </div>
              )
            )
            : <p>К сожалению, публикаций не найдено.</p>
          }
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.nav}>
            {filteredPosts.length !== 0 &&
              <ReactPaginate
                disabledClassName={styles.disabled}
                nextLabel={<NextIcon />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={<PrevIcon />}
                marginPagesDisplayed={1}
                pageClassName={styles.pageItem}
                disableInitialCallback={true}
                nextClassName={styles.nextPage}
                previousClassName={styles.prevPage}
                containerClassName={styles.pagination}
                activeClassName={styles.selected}
                pageLinkClassName={styles.pageLink}
              />
            }
          </div>
      </div>
    </div>
  )
}

export default ArchiveBlog
