import * as React from 'react'
import * as styles from './assets/css/index.module.css'
import * as utilities from '../../../assets/css/utilities.module.css'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Container, Heading, Paragraph } from '../../common'
import QuotesIcon from './assets/images/quotes.svg'
import MissionImage from './assets/images/mission.svg'
import { StaticImage } from 'gatsby-plugin-image'

interface Props {
  seo?: string
}

const About: React.FC<Props> = ({
  seo
}) => (
  <section id="about" className={styles.root}>
    <Container>
      <article className={styles.greeting}>
        <figure className={styles.figure}>
          <div className={styles.wrapper}>
            <StaticImage
              objectFit="cover"
              src="./assets/images/ceo.jpg"
              alt="A dinosaur"
              placeholder="blurred"
              className={styles.image}
            />
          </div>
        </figure>
        <div className={styles.text}>
          <QuotesIcon className={styles.quotes} />
          <Heading>
            <Trans>Добро пожаловать</Trans>
          </Heading>
          <Paragraph className={utilities.mt2_5}>
            <Trans>Мы работаем, чтобы Вы сохранили и приумножили свой капитал, обеспечив финансовую стабильность для себя и своей семьи на долгие годы.</Trans>
          </Paragraph>
          <Paragraph className={utilities.mt1_375}>
            <Trans>Наша компания представляет собой команду профессионалов, работающих в области fintech решений для бизнеса с 2004 года. За всё время в digital-проекте мы выстраивали доверительный диалог с инвесторами и партнёрами, основанный на приоритете мнения наших клиентов, тем самым увеличивая их и наше благосостояние.</Trans>
          </Paragraph>
          <span className={`${utilities.mt0_5} ${styles.signature}`}>
            <Trans>Управляющий партнер Примаченко Алексей Александрович</Trans>
          </span>
        </div>
      </article>
      <article className={`${styles.mission}`}>
        <div className={styles.text}>
          <Heading el='h3' className={utilities.lh125}>
            “<Trans>Наша миссия: удобный электронный факторинг для развивающихся предприятий малого и среднего бизнеса</Trans>”
          </Heading>
          <Paragraph className={`${utilities.mt2_25} ${styles.paragraph}`}>
            <Trans>Мир финансов очень разнообразен и эпоха классических банковских вкладов давно позади. Для нас и наших инвесторов абсолютно приемлем кредитный риск Сбербанка, ВТБ, Газпрома, РЖД, Х5 Retail и других крупных компаний в виде их задолженности перед малым бизнесом, которую мы приобретаем на конкурентной основе, и которая позволяет зарабатывать в разы больше.</Trans>
          </Paragraph>
          <Paragraph className={`${utilities.mt1_375} ${styles.paragraph}`}>
            <Trans>Во всем мире новые технологии лишают банки монополии в кредитовании и выстраивают сотрудничество между конечными игроками, почему этого не должно происходить и в России?</Trans>
          </Paragraph>
        </div>
        <MissionImage className={styles.svg}  />
      </article>
    </Container>

  </section>
)

export default About