import React, { useState } from 'react'
import { Element, Link } from 'react-scroll'
import { documents } from '../content'
import '../assets/css/base.css'
import { About, Advantages, Infographics, Main, Facts, Bonds, Frequently, Documents, Blog, Footer, ModalMenu, SEO } from '../components/sections'
import { graphql } from 'gatsby'

interface Props {
  seo: {}
  data: any
}

const IndexPage: React.FC<Props> = ({
  seo,
  data
}) => {
  const { edges: posts } = data.allMarkdownRemark
  const [modalOpened, setModalOpened] = useState(false)
  const handleStateChange = state => setModalOpened(state.isOpen)
  const toggleModalMenu = () => setModalOpened(!modalOpened)
  const mainClass = modalOpened ? 'modal-opened' : ''
  return (
    <div>
      <SEO title="GLOBAL FACTORING NETWORK" />
      <main className={mainClass}>
        <ModalMenu
          opened={modalOpened}
          onClick={()=>setModalOpened(false)}
        />
        <Element name="main">
          <Main
            onModalToggle={toggleModalMenu}
            modalState = {modalOpened}
          />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <Advantages />
        <Infographics />
        <Facts />
        <Bonds />
        <Element name="faq">
          <Frequently />
        </Element>
        <Element name="blog">
          <Blog posts={posts} />
        </Element>
        <Element name="disclosure">
          <Documents items={documents} />
        </Element>
        <Element name="contacts">
          <Footer />
        </Element>
      </main>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            tags
            date
            excerpt
            featuredImage
            title
          }
          html
        }
      }
    }
  }
`;