// extracted by mini-css-extract-plugin
export var root = "B_b";
export var header = "B_c";
export var grid = "B_j";
export var contact = "B_bD";
export var figure = "B_bF";
export var main = "B_bG";
export var faq = "B_bH";
export var sidebar = "B_bJ";
export var heading = "B_n";
export var categories = "B_bx";
export var category = "B_bK";
export var active = "B_bB";
export var qa = "B_bL";
export var wrapper = "B_r";
export var question = "B_L";
export var answer = "B_K";