import * as React from 'react'
import * as styles from './assets/css/index.module.css'
import { StaticImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Burger, Button, Container } from '../../common'
import { Bean } from '../../bean'
import LanguageSwitcher from './language-switcher'
import { Link as ScrollTo, animateScroll as scroll } from 'react-scroll'
import { Link } from '../../common'

interface Props extends Bean {
  modalState: boolean
  onModalToggle: () => void
}

const Main: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  modalState,
  onModalToggle
}) => {
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <div className={styles.bg}>
        <StaticImage
          src="./assets/images/main-bg-mobile.jpg"
          alt="Main background image"
          imgStyle={{
            objectFit: "none",
            objectPosition: "right bottom",
          }}
          layout="fixed"
          placeholder="blurred"
          quality={90}
          className={`${styles.image}`}
        />
        <StaticImage
          src="./assets/images/main-bg-desktop.jpg"
          alt="Main background image"
          imgStyle={{
            objectFit: "none",
            objectPosition: "right bottom",
          }}
          layout="fixed"
          placeholder="blurred"
          quality={90}
          className={`${styles.image} ${styles.desktop}`}
        />
        <StaticImage
          src="./assets/images/main-bg.jpg"
          alt="Main background image"
          imgStyle={{
            objectFit: "none",
            objectPosition: "right bottom",
          }}
          layout="fixed"
          placeholder="blurred"
          quality={90}
          className={`${styles.image} ${styles.extra}`}
        />
        <Container className={styles.wrapper}>
          <div className={styles.grid}>
            <div className={styles.logo}>
              <StaticImage
                src="./assets/images/logo.png"
                alt="GFN logo"
                placeholder="blurred"
              />
            </div>
            <div className={styles.nav}>
              <ul className={styles.items}>
                <li className={styles.item}>
                  <ScrollTo to="about" spy={true} hashSpy={true} smooth={true}>
                    <Trans>О компании</Trans>
                  </ScrollTo>
                </li>
                <li className={styles.item}>
                  <ScrollTo to="faq" spy={true} hashSpy={true} smooth={true}>
                    <Trans>Вопросы и ответы</Trans>
                  </ScrollTo>
                </li>
                <li className={styles.item}>
                  <ScrollTo to="disclosure" spy={true} hashSpy={true} smooth={true}>
                    <Trans>Раскрытие информации</Trans>
                  </ScrollTo>
                </li>
                <li className={styles.item}>
                  <ScrollTo to="contacts" spy={true} hashSpy={true} smooth={true}>
                    <Trans>Контакты</Trans>
                  </ScrollTo>
                </li>
                <li className={`${styles.item} ${styles.external}`}>
                  <Link href="https://www.factoring-network.ru" target='_blank'>
                    <Trans>Основной сайт</Trans>
                  </Link>
                </li>
                <li className={`${styles.item} ${styles.switcher}`}>
                  <LanguageSwitcher></LanguageSwitcher>
                </li>
                
              </ul>
              <div className={styles.mwr}>
                <LanguageSwitcher></LanguageSwitcher>
                <Burger
                  className={styles.burger}
                  onClick={onModalToggle}
                  checked={modalState}
                />
              </div>
            </div>
            <div className={styles.cta}>
              <h1 className={styles.heading}>
                <Trans>Сберегай с уверенной доходностью</Trans>
              </h1>
              <span className={styles.description}>
                <Trans>Не упустите возможность получать проценты по ставке на порядок выше доходности вкладов</Trans>
              </span>
              <Button className={styles.button} onClick={()=>scroll.scrollToBottom()}>
                <Trans>Купить облигации</Trans>
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
    )

}


export default Main