import React, { useReducer, useState } from 'react'
import { Bean } from '../../bean'
import { v4 as uuid } from 'uuid'
import { useTranslation, Trans, I18nextContext } from 'gatsby-plugin-react-i18next'
import * as styles from './assets/css/index.module.css'
import { Document as IDocument } from './document'
import { Chip, Container, Heading } from '../../common'
import { Category, Document } from '..'
import reducer from './state/reducer'
import { activate, add, deactivate, toggle } from './state/actions'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/ja'

interface Props extends Bean {
  items: Array<IDocument>
}

const Documents: React.FC<Props> = ({
  items = [],
  children,
  className = '',
  style,
}) => {
  const { t } = useTranslation()
  const context = React.useContext(I18nextContext);
  const currentLanguage = context.language
  moment.locale(currentLanguage)
  const categoriesFilter = [...new Set(items.map(item => item.category))]
  const yearsFilter = [...new Set(items.map(item => moment(item.createdAt).year()))]
  const monthsFilter = [...new Set(items.map(item => moment(item.createdAt).month()))]
  const initialState = [
    ...categoriesFilter.map((category) => {
      return {
        id: uuid(),
        active: false,
        byYear: null,
        byMonth: null,
        byCategory: category,
        title: t(category)
      }
    }),
    ...yearsFilter.map((year) => {
      return {
        id: uuid(),
        active: (moment().year() === year) || ((moment().year() - 1 === year)),
        byYear: year,
        byMonth: null,
        byCategory: null,
        title: year
      }
    }),
    ...monthsFilter.map((month) => {
      return {
        id: uuid(),
        active: false,
        byYear: null,
        byMonth: month,
        byCategory: null,
        title: moment.months(month)
      }
    }),
  ]
  const [state, dispatch] = useReducer(reducer, initialState)
  const activeFilters = state.filter((filter) => filter.active)
  const documents = items
    .filter((item) => {
      if (activeFilters.some((filter) => filter.byCategory !== null)) {
        return activeFilters.some((filter) => (filter.byCategory === item.category))
      }
      return true
    })
    .filter((item) => {
      if (activeFilters.some((filter) => filter.byYear !== null)) {
        return activeFilters.some((filter) => (filter.byYear === moment(item.createdAt).year()))
      }
      return true
    })
    .filter((item) => {
      if (activeFilters.some((filter) => filter.byMonth !== null)) {
        return activeFilters.some((filter) => (filter.byMonth === moment(item.createdAt).month()))
      }
      return true
    })

  return (
    <section className={styles.root}>
      <Heading className={styles.heading}><Trans>Актуальные документы</Trans></Heading>
      <Container className={styles.grid}>
        <div className={styles.list}>
          {documents.map((item) => (
            <Document {...item} />
          ))}
        </div>
        <div className={styles.categories}>
          <Heading className={styles.h4}><Trans>Категории</Trans></Heading>
          { state.filter((filter) => filter.byCategory).map((category) => (
            <Category
              onClick={() => dispatch(toggle(category.id))}
              key={category.id}
              opened={category.active}
            >{category.title}</Category>
          ))}
        </div>
        <div className={styles.filter}>
          <Heading className={styles.h4}><Trans>Дата</Trans></Heading>
          <div className={styles.active}>
            { state
              .filter((filter) => filter.active && (filter.byYear || filter.byMonth))
              .map((item) => (
                <Chip onRemove={() => dispatch(deactivate(item.id))} key={item.id}>
                  {item.title}
                </Chip>
            ))}
          </div>
          <div className={styles.years}>
          { state
            .filter((filter) => !filter.active && (filter.byYear || filter.byMonth))
              .map((item) => (
                <div onClick={() => dispatch(activate(item.id))} key={item.id} className={styles.item}>
                  {item.title}
                </div>
          ))}
          </div>
        </div>

      </Container>
    </section>
  )
}

export default Documents