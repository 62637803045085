import * as React from 'react'
import * as styles from './assets/css/fact.module.css'

interface Props {
  title: React.ReactNode
  description: React.ReactNode
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Fact: React.FC<Props> = ({
  title,
  description,
  children,
  className,
  style,
}) => (
  <figure className={`${styles.root} ${className}`} style={style}>
    <div className={styles.icon}>
      {children}
    </div>
    <div className={styles.text}>
      <h6 className={styles.title}>{title}</h6>
      <span className={styles.description}>{description}</span>
    </div>
  </figure>
)

export default Fact