import React, { useState } from 'react'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Bean } from '../../bean'
import { Button, Heading, Input, Textarea } from '../../common'
import * as styles from './assets/css/form.module.css'
import initialState from './state/initialState'
import { FormState, Handler } from './state/types'
import axios from 'axios'

interface Props extends Bean {}

const Form: React.FC<Props> = ({
  className = '',
  style = {},
  children
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState<FormState>(initialState)
  const notEmpty = (value: string): boolean => !!value
  const minLen = (value: string, min: number): boolean => value.length >= min
  const handleChange: Handler<string | boolean> = (name) => (value) => {
    setState({ ...state, [name]: value })
  }
  const validation = {
    name: notEmpty(state.name) && minLen(state.name, 3),
    email: notEmpty(state.email) && minLen(state.email, 5),
    message: notEmpty(state.message),
  }
  const validated = Object.values(validation).every((value) => value)
  const handleSubmit = () => {
    axios.post('https://mailer.d19.tech/send-mail', {
      name: state.name,
      email: state.email,
      message: state.message,
      to: 'info@factoring-network.ru, ovc@digit-it.ru',
      subject: 'Новая заявка с сайта',
    }).then(() => {
      handleChange('success')(`${t('Заявка успешно отправлена, в ближайшее время она будет принята на рассмотрение')}`)
    }).catch((error) => {
      handleChange('error')(`${t('При отправке произошла ошибка. Пожайлуйста, попробуйте позже, наши специалисты уже занимаются этой проблемой. Сообщение сервера:')} ${error.message}`)
    }).finally(() => setTimeout(() => setState(initialState), 5000))
  }
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <div className={styles.header} style={{backgroundImage: `url(/svg/pattern.svg)`}}>
            <Heading className={styles.heading}><Trans>Напишите нам</Trans></Heading>
          </div>
          <div className={styles.inputs}>
            <Input
              placeholder = {t('Имя')}
              validated={validation.name}
              onChange={handleChange('name')}
              value = {state.name}
              name = "name"
            ><Trans>Ваше Имя</Trans></Input>
            <Input
              placeholder = {t('Адрес эл. почты')}
              onChange={handleChange('email')}
              name = "email"
              value = {state.email}
            ><Trans>Эл. почта</Trans></Input>
            <Textarea
              onChange={handleChange('message')}
              name = "message"
              value = {state.message}
            ><Trans>Сообщение</Trans></Textarea>
          </div>
          { state.success && <div className={styles.success}>{state.success}</div> }
          { state.error && <div className={styles.error}>{state.error}</div> }
          { !state.success && !state.error && (
            <div className={styles.submit}>
              <Button
                className={styles.button}
                onClick={handleSubmit}
                disabled={!validated}
              >
                <Trans>Отправить</Trans>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Form