import React, { useState } from 'react'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import * as styles from './assets/css/index.module.css'
import { Bean } from '../../bean'
import { Container, Heading } from '../../common'
import { Events, Posts } from '..'
import ArchiveBlog from './archive'

interface Props extends Bean {
  posts: any
}

const Blog: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  posts,
}) => {
  const [state, setState] = useState(false)
  const archiveShow = () => setState(true)
  const archiveHide = () => setState(false)
  return (
    <section className={styles.root}>
      <Container className={styles.bgrid}>
        <div className={styles.bwrapper}>
          <div className={styles.background} style={{backgroundImage: `url(/svg/pattern.svg)`}}></div>
        </div>
      </Container>
      <Container className={styles.grid}>
        <div className={styles.events}>
          <Heading className={styles.eventsHeading}><Trans>События</Trans></Heading>
          <Events />
        </div>
        <div className={styles.blog}>
          <Heading className={styles.blogHeading}><Trans>Новости эмитента</Trans></Heading>
          {
            state
            ?
            <div className={styles.posts}>
              <ArchiveBlog onArchiveHide={archiveHide} raw={posts} />
            </div>
            :
          <div className={styles.posts}>
            <Posts onArchiveShow={archiveShow} posts={posts.slice(0, 5)} />
          </div>
          }
        </div>
      </Container>
    </section>
  )
}

export default Blog