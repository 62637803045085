import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Bean } from '../../bean'
import { Paragraph } from '../../common'
import * as styles from './assets/css/qa.module.css'
import CollapseIcon from './assets/images/collapse.svg'
import ExpandIcon from './assets/images/expand.svg'

interface Props extends Bean {
  question: React.ReactNode
  opened?: boolean
  onClick: () => void
}

const QA: React.FC<Props> = ({
  children,
  className = '',
  style,
  question,
  opened = false,
  onClick
}) => {
  const transitionClasses = {
    enter: "",
    enterActive: "",
    exit: "",
    exitActive: ""
  }
  const contentRef = useRef(null);
  useEffect(() => {
    contentRef.current.style.maxHeight = opened
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, opened]);
  return (
    <article className={`${styles.root} ${className}`} style={style}>
      <div className={styles.question} onClick={onClick}>
        <div className={styles.text}>{question}</div>
        <div className={styles.arrow}>
          { (opened)? <CollapseIcon />: <ExpandIcon /> }
        </div>
      </div>
      <div className={styles.answer}>
        <div className={`${styles.wrapper} ${opened && styles.visible}`} ref={contentRef}>
          <Paragraph className={styles.paragraph}>{children}</Paragraph>
        </div>
      </div>
    </article>
  )
}

export default QA