import * as React from 'react'
import * as styles from './assets/css/graph.module.css'
import * as utilities from '../../../assets/css/utilities.module.css'

interface Props {
  title?: React.ReactNode
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Graph: React.FC<Props> = ({
  title,
  children,
  className,
  style,
}) => (
  <figure className={styles.root}>
    <h6 className={styles.title}>{title}</h6>
    <div className={utilities.mt1_5}>
      {children}
    </div>
  </figure>
)

export default Graph