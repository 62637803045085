// extracted by mini-css-extract-plugin
export var root = "F_b";
export var bgrid = "F_bf";
export var bwrapper = "F_bg";
export var background = "F_bh";
export var grid = "F_j";
export var contacts = "F_bM";
export var links = "F_3";
export var address = "F_bN";
export var text = "F_J";
export var figure = "F_bF";
export var form = "F_bP";
export var sub = "F_bQ";
export var copyright = "F_bR";
export var company = "F_bS";
export var design = "F_bT";