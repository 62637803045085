import React, { useState } from 'react'
import { useTranslation, Trans, I18nextContext } from 'gatsby-plugin-react-i18next'
import * as Scroll from 'react-scroll'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/ja'
import { Event } from '..'
import { Bean } from '../../bean'
import * as styles from './assets/css/events.module.css'
import ArrowBackIcon from './assets/images/arrow-back.svg'
import ArrowForwardIcon from './assets/images/arrow-forward.svg'
import { Paragraph } from '../../common'

interface Props extends Bean {}

const Events: React.FC<Props> = ({
  className = '',
  style = {},
  children,
}) => {
  const context = React.useContext(I18nextContext);
  const currentLanguage = context.language
  moment.locale(currentLanguage)
  const events = [
    {
      title: "Решение об установлении процентных ставок",
      date: "2021-08-04T00:00:01Z",
      content: `4 августа 2021 года Приказом Генерального директора ООО «Глобал Факторинг Нетворк Рус» принято 
      решение об установлении процентных ставок на 5 и 6 купонные периоды коммерческих облигаций 3-го выпуска 
      (рег. номер 4CDE-03-00381-R-001P) в размере 13% годовых. Таким образом, принято решение о сохранении 
      процентной ставки на прежнем уровне.
      В связи с принятым решением владельцы вышеуказанных облигаций имеют возможность в период с 16 по 20 
      августа 2021 года включительно подать заявления на участие в оферте (досрочном выкупе эмитентом). 
      Дата приобретения – 25.08.2021 года.`
    },
    {
      title: "Решение об установлении процентных ставок",
      date: "2021-08-05T00:00:01Z",
      content: `5 августа 2021 года Приказом Генерального директора ООО «Глобал Факторинг Нетворк Рус» принято 
      решение об установлении процентных ставок на купонные периоды с 5 по 12 коммерческих облигаций 7-го выпуска 
      (рег. номер 4CDE-07-00381-R-001P) в размере 13% годовых. Таким образом, принято решение о сохранении 
      процентной ставки на прежнем уровне.
      В связи с принятым решением владельцы вышеуказанных облигаций имеют возможность в период с 18 по 24 
      августа 2021 года включительно подать заявления на участие в оферте (досрочном выкупе эмитентом). 
      Дата приобретения – 27.08.2021 года.`
    },
    {
      title: "Выплаты по выпуску 4CDE-01-00381-R-002P",
      date: "2021-08-18T00:00:01Z",
      content: `Выплаты по выпуску 4CDE-01-00381-R-002P, № купонного периода: 8, Ставка купона: 12,5%`
    },
    {
      title: "Выплаты по выпуску 4CDE-03-00381-R-001P",
      date: "2021-08-20T00:00:01Z",
      content: `Выплаты по выпуску 4CDE-01-00381-R-002P, № купонного периода: 4, Ставка купона: 13`
    },
    {
      title: "Выплаты по выпуску 4CDE-07-00381-R-001P",
      date: "2021-08-24T00:00:01Z",
      content: `Выплаты по выпуску 4CDE-01-00381-R-002P, № купонного периода: 4, Ставка купона: 13%`
    },
    {
      title: "Выплаты по выпуску 4CDE-02-00381-R-002P",
      date: "2021-08-24T00:00:01Z",
      content: `Выплаты по выпуску 4CDE-01-00381-R-002P, № купонного периода: 6, Ставка купона: 12,5%`
    },
  ]
  const [ currentContent, setCurrentContent ] = useState('')
  const [ contentOpened, setContentOpened ] = useState(false)
  const setCurrentContentHandler = (content: string) => {
    Scroll.scroller.scrollTo('blog', {
      smooth: true,
      spy: true,
      hashSpy: true,
      duration: 250,
    })
    setCurrentContent(content)
    setContentOpened(true)
  }
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <nav className={styles.nav}>
        <div
          className={`${styles.prev} ${!contentOpened && styles.hide}`}
          onClick={() => setContentOpened(false)}
        >
          <div className={styles.icon}>
            <ArrowBackIcon />
          </div>
          <span className={styles.title}><Trans>Назад</Trans></span>
        </div>
        <div className={`${styles.prev} ${styles.disabled} ${contentOpened && styles.hide}`}>
          <div className={styles.icon}>
            <ArrowBackIcon />
          </div>
          <span className={styles.title}>{moment.months(6)}</span>
        </div>
        <div className={`${styles.next} ${styles.disabled} ${contentOpened && styles.hide}`}>
          <span className={styles.title}>{moment.months(8)}</span>
          <div className={styles.icon}>
            <ArrowForwardIcon />
          </div>
        </div>
      </nav>
      <div className={styles.wrapper}>
        <div className={`${styles.list} ${contentOpened && styles.closed}`}>
          { events.map((event) => (
            <div className={styles.event}>
              <Event
                date={moment(event.date).format('D MMMM')}
                onClick={() => setCurrentContentHandler(event.content)}
              >{event.title}</Event>
            </div>
          ))}
        </div>
        <div className={`${styles.content} ${contentOpened && styles.opened}`}>
          <Paragraph className={styles.paragraph}>{currentContent}</Paragraph>
        </div>
      </div>
    </div>
  )
}

export default Events