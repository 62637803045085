import React from 'react'
import { Link } from 'react-scroll'
import { Trans } from 'gatsby-plugin-react-i18next'
import Form from './form'
import { Bean } from '../../bean'
import { Container, Heading, Social } from '../../common'
import { FacebookIcon, InstagramIcon, MailIcon, PhoneIcon, SiteIcon } from '../footer/images'
import * as styles from './assets/css/index.module.css'

interface Props extends Bean {
  opened: boolean
  onClick: () => void
}

const ModalMenu: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  opened,
  onClick,
}) => {
  return (
    <div className={`${styles.root} ${opened && styles.opened}`}>
      <Container className={styles.grid}>
        <div className={styles.nav}>
          <Heading el='h2' className={styles.heading}>
            <Trans>Навигация</Trans>
          </Heading>
          <ul className={styles.items}>
            <li className={styles.item}>
              <Link hashSpy={true} spy={true} smooth={true} to="about" onClick={onClick}>
                <Trans>О компании</Trans>
              </Link>
            </li>
            <li className={styles.item}>
              <Link smooth={true} to="faq" hashSpy={true} spy={true} onClick={onClick}>
                <Trans>Вопросы и ответы</Trans>
              </Link>
            </li>
            <li className={styles.item}>
              <Link smooth={true} to="disclosure" hashSpy={true} spy={true} onClick={onClick}>
                <Trans>Раскрытие информации</Trans>
              </Link>
            </li>
            <li className={styles.item}>
              <Link smooth={true} to="contacts" hashSpy={true} spy={true} onClick={onClick}>
                <Trans>Контакты</Trans>
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.contacts}>
          <Heading el='h2' className={styles.heading}>
            <Trans>Связаться</Trans>
          </Heading>
          <div className={styles.links}>
            {/* <Social to="http://instagram.com/global_factoring_network"><InstagramIcon /></Social>
            <Social to="https://www.facebook.com/Global-Factoring-Network-109929046042187"><FacebookIcon /></Social> */}
            <Social to="mailto:info@factoring-network.ru"><MailIcon /></Social>
            <Social to="tel:+78007756970"><PhoneIcon /></Social>
            <Social to="https://www.factoring-network.ru"><SiteIcon /></Social>
          </div>
        </div>
        {/* <div className={styles.form}>
          <Heading el='h2' className={styles.heading}>Напишите нам</Heading>
            <Form />
        </div> */}
      </Container>
    </div>
  )
}

export default ModalMenu