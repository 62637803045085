import { FormState } from './types'

export default <FormState> {
  name: '',
  email: '',
  message: '',
  agreement: false,
  validated: false,
  error: '',
  success: '',
}
