import React from 'react'
import { Bean } from '../../bean'
import { Heading, Paragraph } from '../../common'
import * as styles from './assets/css/post.module.css'

interface Props extends Bean {
  size?: 'small' | 'normal'
  title: string
  featuredImage: string
  url: string
}

const Post: React.FC<Props> = ({
  className = '',
  style = {},
  children,
  size = 'normal',
  title,
  featuredImage,
  url,
}) => {
  return (
    <article className={`${styles.root} ${styles[size]} ${className}`} style={style}>
      <a className={styles.link} href={`/${url}`}>
        <div className={styles.wrapper}>
          <img src={featuredImage} className={styles.image} alt={title} />
          <div className={styles.layout}></div>
        </div>
        <div className={styles.grid}>
          <div className={styles.meta}>
            <Heading className={styles.title}>
              {title}
            </Heading>
            <Paragraph className={styles.excerpt}>
              {children}
            </Paragraph>
          </div>
        </div>
      </a>
    </article>
  )
}

export default Post