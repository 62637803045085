import { FilterItem } from './types'

export enum Type {
  Added = 'ADDED',
  Removed = 'REMOVED',
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Toggled = 'TOGGLED'
}

export interface Action {
  type: Type
  payload: any
}

export const add = (filter: Omit<FilterItem, 'id'>) => {
  return {
    type: Type.Added,
    payload: filter
  }
}

export const remove = (id: FilterItem['id']) => {
  return {
    type: Type.Added,
    payload: { id }

  }
}

export const activate = (id: FilterItem['id']) => {
  return {
    type: Type.Activated,
    payload: { id }
  }
}
export const deactivate = (id: FilterItem['id']) => {
  return {
    type: Type.Deactivated,
    payload: { id }
  }
}
export const toggle = (id: FilterItem['id']) => {
  return {
    type: Type.Toggled,
    payload: { id }
  }
}