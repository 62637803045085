import React from 'react'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Form } from '..'
import { Bean } from '../../bean'
import { Container, Heading, Paragraph, Social } from '../../common'
import { CustomerSupport, InstagramIcon, FacebookIcon, MailIcon, PhoneIcon, SiteIcon } from './images'
import * as styles from './assets/css/index.module.css'
import { StaticImage } from 'gatsby-plugin-image'

interface Props extends Bean {}

const Footer: React.FC<Props> = ({
  className = '',
  style = {},
  children
}) => {
  return (
    <>
      <footer className={`${styles.root} ${className}`} style={style}>
      <Container className={styles.bgrid}>
        <div className={styles.bwrapper}>
          <div className={styles.background}>
            <StaticImage
              src="./assets/images/bg.jpg"
              alt="Footer background"
              placeholder="blurred"
            />
          </div>
        </div>
      </Container>
        <Container className={styles.grid}>
          <div className={styles.contacts}>
            <Heading><Trans>Контактная информация</Trans></Heading>
            <div className={styles.links}>
              {/* <Social to="http://instagram.com/global_factoring_network"><InstagramIcon /></Social>
              <Social to="https://www.facebook.com/Global-Factoring-Network-109929046042187"><FacebookIcon /></Social> */}
              <Social to="mailto:info@factoring-network.ru"><MailIcon /></Social>
              <Social to="tel:+78007756970"><PhoneIcon /></Social>
              <Social to="https://www.factoring-network.ru"><SiteIcon /></Social>
            </div>
            <div className={styles.address}>
              <Paragraph className={styles.text}><Trans>ООО «Глобал Факторинг Нетворк Рус»</Trans></Paragraph>
              <Paragraph className={styles.text}><Trans>125284, г. Москва, Хорошевское шоссе, дом 32А</Trans></Paragraph>
              <Paragraph className={styles.text}><Trans>620100, г. Екатеринбург, ул. Ткачей, дом 23, корпус В, БЦ Clever park</Trans></Paragraph>
              <Paragraph className={styles.text}><Trans>ОГРН 1117746229864 / ИНН 7714835008</Trans></Paragraph>
              <Paragraph className={styles.text}><Trans>8-800-775-69-70</Trans></Paragraph>
            </div>
            <figure className={styles.figure}><CustomerSupport /></figure>
          </div>
          <div className={styles.form}>
            <Form></Form>
          </div>
        </Container>
      </footer>
      <section className={styles.sub}>
        <Container className={styles.copyright}>
          <Paragraph className={styles.company}>© <Trans>ООО «Глобал Факторинг Нетворк Рус»</Trans>, {new Date().getFullYear()}</Paragraph>
          <Paragraph className={styles.design}><Trans>Разработано в d19</Trans></Paragraph>
        </Container>
      </section>
    </>
  )
}

export default Footer